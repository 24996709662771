<script setup lang="ts">
import { ref, type PropType } from 'vue';
import type { BlogPost } from '~/types/blog.type';
import HeaderLinks from './partials/header-links.vue';
import Comments from './partials/comments.vue';
import AdBanner from './partials/ad-banner.vue';
import type { MetaAttributes } from '~/types/meta-attributes.type';
import { useMetaStore } from '~/stores/meta.store';

const props = defineProps({
	data: { type: Object as PropType<BlogPost>, required: true },
	meta: {type: Object as PropType<MetaAttributes>, required: true}
});

const metaStore = useMetaStore();
const meta = ref({});
metaStore.getMetaTags()

meta.value = metaStore.metaGetter();
metaStore.setMeta({...props.meta});
</script>

<template>
	<LayoutAppHead></LayoutAppHead>
	<NuxtLayout name="desktop-blog">
		<main>
			<section v-if="data">
				<HeaderLinks :country_id="data.blog_country_id" />
				<div class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8 mb-8">
					<div class="gap-4 mx-0">
						<div class="col-span-3">
							<div class="flex flex-col">
								<!-- Post Header -->
								<div>
									<img v-if="data.picture" class="w-full object-cover rounded-xl h-96" :src="data.picture" :alt="data.title">
									<div class="flex justify-between w-full my-3">
										<h2 class="text-gray-800 text-2xl font-medium mt-2">
											{{ data.title }}
										</h2>
										<div class="flex-shrink-0">
											<p class="flex items-center gap-3 text-gray-400 mt-3">
												<small class="me-3">
													<i class="fa-solid fa-clock me-2"></i>
													{{ data.reading_times }} mins read
												</small>
												<small class="me-3">
													<i class="fa-solid fa-calendar me-2"></i>
													{{ new Date(data.created_at).toLocaleDateString() }}
												</small>
												<small class="me-3">
													<i class="fa-solid fa-comments me-2"></i>
													Comments ({{ data.comments_count || 0 }})
												</small>
											</p>
										</div>
									</div>
								</div>

								<!-- Post Contents -->
								<div class="my-3">
									<div v-html="data.contents"></div>
								</div>

								<!-- <Comments /> -->
							</div>
						</div>
						<div>
							<!-- <div class=" bg-white rounded-xl border">
								<div class="flex flex-col pt-3">
									<a href="#" class="flex text-center justify-center w-full pt-3">
										<img class="w-auto lg:h-12 sm:h-2" src="https://placehold.co/100x20"
											alt="Vartur">
									</a>
									<form action="" class="flex flex-col mt-3 p-3">
										<input type="text"
											class="ring-1 ring-inset ring-indigo-100 p-2 mb-3 rounded text-center"
											placeholder="Enter Your Name">
										<input type="text"
											class="ring-1 ring-inset ring-indigo-100 p-2 mb-3 rounded text-center"
											placeholder="Enter Your Email">
									</form>
									<button type="button" class="btn-subscribe">Subscribe</button>
								</div>
							</div> -->
							<!-- <AdBanner /> -->
						</div>
					</div>
				</div>
			</section>
		</main>
	</NuxtLayout>
</template>
